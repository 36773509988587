<template>
    <div>
        <b-card title="Create User Group">
            <b-row style="margin-top: 20px">
                <b-col></b-col>
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm"
                    :hidden="disableComponent">
                    <feather-icon icon="UploadIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Edit User Group
                </b-button>
                <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/usergroup">
                    <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Go Back
                </b-button>
                <b-col></b-col>
            </b-row>
            <b-tabs content-class="mt-2" pills>
                <b-tab active>
                    <template #title>
                        <feather-icon icon="FileTextIcon" />
                        <span>User Group Info</span>
                    </template>
                    <validation-observer ref="simpleRules">
                        <b-form>
                            <b-row></b-row>
                            <b-row>
                                <b-col cols="1"></b-col>
                                <b-col>
                                    <validation-provider #default="{ errors }" name="ID_user_group" rules="required">
                                        <h5>ID_user_group</h5>
                                        <b-form-input v-model="body.ID_user_group" :disabled="disableComponent"
                                            :state="errors.length > 0 ? false : null" autofocus />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="1"></b-col>
                                <b-col>
                                    <validation-provider #default="{ errors }" name="Description" rules="required">
                                        <h5>Description</h5>
                                        <b-form-input v-model="body.Description" :disabled="disableComponent"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="1"></b-col>
                                <b-col>
                                    <validation-provider #default="{ errors }" name="Comments" rules="required">
                                        <h5>Comments</h5>
                                        <b-form-input v-model="body.Comments" :disabled="disableComponent"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="1"></b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-tab>
                <b-tab>
                    <template #title>
                        <feather-icon icon="MonitorIcon" />
                        <span>Web Permissions</span>
                    </template>
                    <b-card no-body class="border mt-1">
                        <b-card-header class="p-1">
                            <b-card-title class="font-medium-2">
                                <feather-icon icon="LockIcon" size="18" />
                                <span class="align-middle ml-50">Permission</span>
                            </b-card-title>
                        </b-card-header>
                        <b-table striped responsive class="mb-0" :fields="webFields" :items="webItems">
                            <template #cell(Check)="data">
                                <b-form-checkbox :checked="data.value" :disabled="disableComponent"
                                    v-model="selectedWebItems[selectedWebItems.findIndex(item => item.id === data.item.ID_web_action)].check" />
                            </template>
                        </b-table>
                    </b-card>
                </b-tab>
                <b-tab>
                    <template #title>
                        <feather-icon icon="ListIcon" />
                        <span>Task Permissions</span>
                    </template>
                    <b-card no-body class="border mt-1">
                        <b-card-header class="p-1">
                            <b-card-title class="font-medium-2">
                                <feather-icon icon="LockIcon" size="18" />
                                <span class="align-middle ml-50">Permission</span>
                            </b-card-title>
                        </b-card-header>
                        <b-table striped responsive class="mb-0" :fields="taskFields" :items="taskItems">
                            <template #cell(Check)="data">
                                <b-form-checkbox :checked="data.value" :disabled="disableComponent"
                                    v-model="selectedTaskItems[selectedTaskItems.findIndex(item => item.id === data.item.ID_task_action)].check" />
                            </template>
                        </b-table>
                    </b-card>
                </b-tab>
            </b-tabs>
            <b-row></b-row>
            <b-row style="margin-top: 20px">
                <b-col></b-col>
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm"
                    :hidden="disableComponent">
                    <feather-icon icon="UploadIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Create User Group
                </b-button>
                <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/usergroup">
                    <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Go Back
                </b-button>
                <b-col></b-col>
            </b-row>
        </b-card>
    </div>
</template>
  
<script>
import { BCard, BForm, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BButton, BTabs, BTab, BCardHeader, BCardTitle, BTable, BFormCheckbox } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import { decryptString } from '@/libs/crypto'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BButton, BTabs, BTab, BCardHeader, BCardTitle, BTable, BFormCheckbox, ValidationProvider, ValidationObserver
    },
    computed: {

    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.message();
                }
            })
        },
        async message() {
            this.body.taskActions = this.selectedTaskItems;
            this.body.webActions = this.selectedWebItems;

            const error = await this.onSubmit();
            if (error[0]) {
                const variant = "danger";
                this.$bvToast.toast("" + error[1], {
                    title: "Failed",
                    variant,
                    solid: true,
                });
            } else if (!error[0]) {
                this.disableComponent = true;
                const variant = "success";
                this.$bvToast.toast("User Group created correctly", {
                    title: `Create`,
                    variant,
                    solid: true,
                });
            }
        },
    },
    data() {
        return {
            required,
            disableComponent: false,
            taskFields: [
                { class: "chkCol", key: "Check", label: "" },
                { key: "ID_task_action", label: "ID_task_action" },
                { key: "action_name", label: "Task_action_name" },
                { key: "Description", label: "Description" },
            ],
            taskItems: [],
            selectedTaskItems: [],
            webFields: [
                { class: "chkCol", key: "Check", label: "" },
                { key: "ID_web_action", label: "ID_web_action" },
                { key: "web_action_name", label: "Web_action_name" },
                { key: "descripcion", label: "Description" },
            ],
            webItems: [],
            selectedWebItems: [],
        };
    },
    async created() {
        await axios.get("/web-action")
            .then((results) => {
                this.webItems = results.data;
                this.webItems.forEach((element) => {
                    this.selectedWebItems.push({ id: element.ID_web_action, check: false })
                })
            })
            .catch();
        await axios.get("/task-action")
            .then((results) => {
                this.taskItems = results.data;
                this.taskItems.forEach((element) => {
                    this.selectedTaskItems.push({ id: element.ID_task_action, check: false })
                })
            })
            .catch();
    },
    mounted() {
        if (localStorage.getItem("accessToken") === null) {
            this.$router.go(0)
        }
    },
    setup() {
        const blankAforoAccesData = {
            ID_user_group: "",
            Description: "",
            Comments: "",
            taskActions: "",
            webActions: "",
            IdUser: decryptString(localStorage.getItem('idUser')),
        };

        const body = ref(JSON.parse(JSON.stringify(blankAforoAccesData)));

        const onSubmit = async () => {
            const onError = [false, ""];
            await axios
                .post("/user-group", body.value)
                .then(() => {
                    onError[0] = false;
                })
                .catch((error) => {
                    onError[0] = true;
                    onError[1] = error;
                });
            return onError;
        };

        return {
            body,
            onSubmit,
        };
    },
};
</script>
<style>
.chkCol {
    width: 30px;
}
</style>